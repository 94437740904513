.footer {
    width: calc(100% - 160px);
    height: 484px;
    position: relative;
    padding: 64px;
    margin: 0 auto 16px auto;

    border-radius: 64px;
    background: #000;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &__container {
        height: 332px;
        width: 1280px;
        position: relative;

        display: flex;
        justify-content: flex-start;
        gap: 124px;
    }

    &__vector {
        width: 632px;
        height: 330px;
        position: relative;

        background: url('../../../assets/images/footer/vector.svg') no-repeat;
    }

    &__menu {
        width: auto;
        height: 332px;
        position: relative;

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 16px;

        &__left,
        &__right {
            width: 200px;
            height: 100%;
            position: relative;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &__column {
            width: 100%;
            height: auto;
            position: relative;

            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 16px;

            &__item {
                width: auto;
                height: 16px;
                position: relative;

                color: #D9D9D9;
                font-family: 'Satoshi', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                letter-spacing: -0.32px;

                cursor: pointer;
            }

            &__contact {
                width: auto;
                height: 24px;

                color: #D9D9D9;
                text-shadow: 0px 1px 32px rgba(8, 24, 93, 0.25);
                font-family: 'Satoshi', sans-serif;
                font-size: 16px;
                font-weight: 500;
                line-height: 150%;
                letter-spacing: -0.32px;
                text-decoration-line: underline;

                cursor: pointer;
            }
        }

        &__row {
            width: 100%;
            height: 24px;
            position: relative;

            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            gap: 16px;

            &__item {
                width: auto;
                height: 24px;
                position: relative;

                color: #D9D9D9;
                text-shadow: 0px 1px 32px rgba(8, 24, 93, 0.25);
                font-family: 'Satoshi', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
                letter-spacing: -0.32px;

                cursor: pointer;
            }
        }
    }

    &__line {
        width: 1280px;
        height: 2px;
        background: #333;
    }

    &__row {
        width: 1280px;
        height: 24px;
        position: relative;

        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &__item {
            height: 24px;
            position: relative;

            color: #BFBFBF;
            text-shadow: 0px 1px 32px rgba(8, 24, 93, 0.25);
            font-family: 'Satoshi', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            letter-spacing: -0.32px;
        }

        &__item:nth-child(1) {
            width: 200px;
        }

        #privacy,
        #terms {
            cursor: pointer;
        }

        &__links {
            height: 100%;
            width: 524px;

            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            gap: 16px;
        }
    }

    @media (min-width: 768px) and (max-width: 1366px) {
        width: calc(100% - 64px - 82px);
        // padding-top: 32px;
        // padding-bottom: 43px;
        // padding-right: 47px;
        // padding-left: 35px;

        padding: 32px 47px 43px 35px;

        &__container {
            width: 960px;
            height: 248px;
            gap: 89px;
        }

        &__menu {
            height: 248px;

            &__left,
            &__right {
                width: 153px;
            }
        }

        &__vector {
            max-width: 476px;
            max-height: 248px;
            background-size: contain;
        }

        &__line {
            width: calc(100% - 64px - 82px);
            max-width: 960px;
        }

        &__row {
            width: calc(100% - 64px - 82px);
            max-width: 960px;

            &__links {
                width: 395px;
            }
        }
    }

    @media (max-width: 440px) {
        width: 295px;
        height: 496px;
        padding: 32px;
        margin-bottom: 8px;

        border-radius: 32px;
        // overflow: hidden;

        #privacy,
        #terms,
        #delabs {
            color: #A6A6A6;
        }

        &__line {
            width: 100%;
            position: relative;
        }

        &__vector {
            width: 216px;
            height: 111px;
            position: relative;
            transform: rotate(-90deg);
            margin: 0px 0 0 -55px;

            background-size: cover;
        }

        &__container {
            width: 295px;
            height: 100%;

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            gap: 32px;

            &__segment {
                width: 100%;
                height: 360px;

                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .footer__container__section {
                    &:last-child {
                        height: auto;
                    }
                }
            }

            &__section {
                width: 100%;
                position: relative;

                display: flex;
                flex-direction: column;
                justify-content: space-between;

                &__links,
                &__links-row {
                    &__item {
                        color: #D9D9D9;
                        font-family: 'Satoshi', sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                    }
                }

                &__links {
                    width: 100%;

                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    gap: 16px;

                    &__item {
                        width: 100%;
                        height: 16px;
                        line-height: 100%;
                    }
                }

                &__links-row {
                    width: 100%;
                    height: 24px;

                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    gap: 16px;

                    &__item {
                        text-shadow: 0px 1px 32px rgba(8, 24, 93, 0.25);
                        line-height: 150%;
                    }
                }

                &__links-column {
                    width: 100%;
                    height: 64px;

                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    &__item {
                        height: 24px;
                        margin: 0;

                        color: #D9D9D9;
                        text-shadow: 0px 1px 32px rgba(8, 24, 93, 0.25);
                        font-family: 'Satoshi', sans-serif;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 150%;
                        letter-spacing: -0.32px;
                        text-decoration-line: underline;
                    }
                }

                &:last-child {
                    height: 72px;
                }
            }
        }
    }
}