.error {
    width: 100%;
    height: 762px;

    &__background {
        position: absolute;
        top: 79px;
        left: 0;
        right: 0;

        color: #BBB;
        font-family: 'Satoshi', sans-serif;
        font-size: 778.633px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        letter-spacing: -31.145px;
        opacity: 0.1;

        display: none;
    }

    &__title {
        width: 250px;
        height: 128px;
        position: relative;
        margin: 337px auto 0 auto;
        text-align: center;

        color: #262626;
        font-family: 'Satoshi', sans-serif;
        font-size: 128px;
        font-style: normal;
        font-weight: 900;
        line-height: 100%;
        letter-spacing: -5.12px;

    }

    &__text {
        position: relative;
        margin: 16px auto 0 auto;
        text-align: center;

        color: #262626;
        font-family: 'Satoshi', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
    }

    &__button {
        width: 167.27px;
        height: 24px;

        margin: 85px auto 0 auto;

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 11px;

        color: #000;
        text-align: center;
        font-family: 'Satoshi', sans-serif;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;

        cursor: pointer;

        &__icon {
            width: 24px;
            height: 24px;

            position: relative;

            background: url('../../assets/images/evolve/button.svg') no-repeat;
            background-size: contain;
        }
    }

    @media (max-width: 500px) {
        height: 80vh;

        &__title {
            width: 374px;
            height: 195px;
            margin-top: 142px;
            font-size: 194.919px;
            font-weight: 700;
            letter-spacing: -7.797px;
        }

        &__text {
            font-size: 22px;
        }

        &__button {
            margin-top: 55px;
        }
    }
}