.header {
    width: 100%;
    // height: 32px;
    position: fixed;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 0;
    padding: 30px 0;

    background: #fff;
    z-index: 100;

    &__container {
        width: 1280px;
        height: 32px;
        position: relative;
        margin: 0 auto;

        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__left {
        width: 318px;
        height: 25px;
        position: relative;

        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__right {
        width: 457px;
        height: 32px;
        position: relative;

        display: flex;
        justify-content: space-between;
        align-items: center;

        &__container {
            width: 350px;
            height: 18px;
            position: relative;

            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__title {
        width: 109px;
        height: 25px;

        position: relative;

        display: flex;
        justify-content: space-between;
        align-items: center;

        cursor: pointer;

        h2 {
            color: #262626;

            font-family: 'Satoshi', sans-serif;
            font-size: 24.979px;
            font-style: normal;
            font-weight: 900;
            line-height: 100%;
            letter-spacing: -0.5px;

            margin: 0;
        }

        .logo {
            width: 22.708px;
            height: 22.708px;

            margin-top: 3px;

            background: url('../../../assets/images/logo.svg') no-repeat;
            background-size: contain;
        }
    }

    &__btn {
        height: 18px;

        position: relative;
        margin: 0;

        font-family: 'Satoshi', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        letter-spacing: -0.36px;
        color: #262626;
        text-decoration: none;

        cursor: pointer;
    }

    &__menu-icon {
        width: 32px;
        height: 32px;
        position: relative;

        background-size: contain;

        cursor: pointer;
    }

    &__menu {
        width: 100%;
        height: calc(100% - 64px);
        margin: 16px auto 0 auto;
        position: relative;

        &__links {
            // width: calc(100% - 353px);
            width: 1087px;
            height: 298px;
            position: absolute;
            left: 80px;
            top: 140px;

            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-content: space-between;
            gap: 48px;
        }

        &__link {
            width: 439px;
            height: 64px;

            color: #000;
            font-family: 'Satoshi', sans-serif;
            font-size: 64px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: -2.56px;

            &:last-child {
                display: none;
            }
        }

        &__language {
            width: 74px;
            height: 22px;
            position: absolute;
            left: 728px;
            top: 404px;

            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .active {
                color: #000;
            }
        }

        &__lang {
            width: 25px;
            height: 14px;
            margin: 0;

            color: #787878;
            font-family: 'Satoshi', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        &__contacts {
            width: 317px;
            height: 16px;
            position: absolute;
            left: 728px;
            bottom: 40px;

            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            gap: 77px;
        }

        &__contact {
            height: 16px;
            position: relative;

            color: #000;
            font-family: 'Satoshi', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
        }

        &__rights {
            width: 201px;
            height: 28px;
            margin: 0;
            position: absolute;
            left: 80px;
            bottom: 43px;

            color: #787878;
            font-family: 'Satoshi', sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;

            a {
                color: #787878;
            }
        }

        @media (min-width: 1440px) {
            &__links {
                left: calc(calc(100% - 1440px) / 2 + 80px);
            }

            &__language {
                left: calc(calc(100% - 1440px) / 2 + 728px);
            }

            &__contacts {
                left: calc(calc(100% - 1440px) / 2 + 728px);
            }

            &__rights {
                left: calc(calc(100% - 1440px) / 2 + 80px);
            }
        }
    }

    @media (min-width: 768px) and (max-width: 1280px) {
        &__container {
            width: calc(100% - 64px);
        }

        &__right {
            width: 457px;

            &__container {
                width: 350px;
            }
        }
    }

    @media (max-width: 440px) {
        width: calc(100vw - 16px);
        margin: 0;
        padding: 16px 8px;

        &__container {
            width: 343px;
            margin: 0 auto;
        }

        &__left {
            width: 32px;
            height: 32px;

            span {
                display: none;
            }
        }

        &__title {
            width: 32px;
            height: 32px;

            h2 {
                display: none;
            }

            .logo {
                width: 32px;
                height: 32px;
            }
        }

        &__right {
            width: 32px;
            height: 32px;
            position: absolute;

            right: 0;
            top: 0;

            &__container {
                display: none;
            }
        }

        &__menu {
            width: 343px;
            height: calc(100% - 64px);
            margin: 16px auto 0 auto;
            position: relative;

            &__links {
                width: 172px;
                height: 224px;
                position: absolute;
                left: 0;
                top: 95px;

                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 16px;
            }

            &__link {
                width: 100%;
                height: 24px;

                color: #000;
                font-family: 'Satoshi', sans-serif;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                letter-spacing: -0.48px;

                &:last-child {
                    display: block;
                }
            }

            &__language {
                width: 66px;
                height: 14px;
                position: absolute;
                left: 0;
                top: 350px;

                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .active {
                    color: #000;
                }
            }

            &__lang {
                width: 25px;
                height: 14px;
                margin: 0;

                color: #787878;
                font-family: 'Satoshi', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                letter-spacing: -0.28px;
            }

            &__contacts {
                width: 243px;
                height: 14px;
                position: absolute;
                left: 0;
                top: 460px;

                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                gap: 40px;
            }

            &__contact {
                height: 14px;
                position: relative;

                color: #000;
                font-family: 'Satoshi', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                letter-spacing: -0.28px;
            }

            &__rights {
                width: 201px;
                height: 28px;
                margin: 0;
                position: absolute;
                left: 0;
                bottom: 29px;

                color: #787878;
                font-family: 'Satoshi', sans-serif;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;

                a {
                    color: #787878;
                }
            }
        }
    }
}