.form {
    width: 916px;
    height: 1768px;
    position: relative;
    margin: 0 auto;
    padding-top: 134px;

    padding-left: 196px;
    padding-right: 128px;

    h2 {
        height: 34px;
        margin: 0;

        color: #262626;
        font-family: 'Satoshi', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
    }

    &__title {
        width: 916px;
        height: 114px;
        position: relative;
        margin-bottom: 64px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h1 {
            height: 64px;
            margin: 0;

            color: #262626;
            font-family: 'Satoshi', sans-serif;
            font-size: 64px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: -2.56px;
        }

        p {
            height: 34px;
            margin: 0;

            color: #262626;
            font-family: 'Satoshi', sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
        }
    }

    &__type,
    &__budget,
    &__details {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 32px;
    }

    &__type {
        width: 788px;
        height: 714px;
        margin-bottom: 64px;
    }

    &__budget {
        width: 792px;
        height: 114px;
        margin-bottom: 64px;
    }

    &__details {
        width: 848px;
        height: 222px;
        margin: 0;

        &__fields {
            width: 100%;
            height: 156px;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        input {
            width: 100%;
            height: 22px;
            padding-bottom: 14px;
            border: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);

            color: #1C1C1C;
            font-family: 'Satoshi', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;

            outline: none;

            &::placeholder {
                color: #A6A6A6;
            }
        }
    }

    &__submit {
        width: 220px;
        height: 220px;
        background: #1C1C1C;
        position: relative;

        margin: 32px auto 16px 266px;

        color: #FFF;
        font-family: 'Satoshi', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 220px;
        text-align: center;
        border-radius: 50%;

        cursor: pointer;
    }

    &__note {
        color: #A6A6A6;
        font-family: 'Satoshi', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
    }

    @media (max-width: 440px) {
        height: auto;
        width: 343px;

        padding: 128px 16px 64px 16px;

        &__title {
            width: 343px;
            height: 92px;
            margin-bottom: 128px;

            h1 {
                height: 32px;
                font-size: 32px;
                font-weight: 700;
                line-height: 100%;
                letter-spacing: -0.64px;
            }

            p {
                height: 44px;
                font-size: 16px;
                font-weight: 400;
                line-height: 140%;
            }
        }

        &__type,
        &__budget,
        &__details {
            gap: 24px;
        }

        &__type {
            width: 100%;
            height: 644px;
            margin-bottom: 128px;
        }

        &__budget {
            width: 100%;
            height: 192px;
            margin-bottom: 128px;
        }

        &__details {
            width: 100%;
            height: 244px;

            &__fields {
                height: 186px;
            }

            input {
                padding: 12px 0;
            }
        }

        &__submit {
            margin: 32px auto 16px auto;
        }

        &__note {
            width: 100%;
            text-align: center;
        }
    }
}

.picker {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;

    .active {
        border: 3px solid var(--Black-400, #BDBDBD);
    }

    &--vertical {
        flex-direction: column;
        justify-content: flex-start;
        align-items: self-start;
        gap: 12px;
    }

    &--horizontal {
        flex-direction: row;
        justify-content: flex-start;
        gap: 14px;
    }

    &__item {
        width: auto;
        height: 22px;
        padding: 13px 24px;
        position: relative;

        color: #333;
        font-family: 'Satoshi', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;

        border-radius: 37px;
        border: 0.5px solid #BDBDBD;

        cursor: pointer;
    }

    @media (max-width: 440px) {
        &--vertical {
            gap: 8px;
        }

        &--horizontal {
            flex-wrap: wrap;
            gap: 8px;
        }

        &__item {
            height: 18px;
            font-size: 14px;
            font-weight: 500;
            line-height: 140%;
        }
    }
}