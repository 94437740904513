.cookies {
    width: 1280px;
    position: relative;
    margin: 0 auto;

    .title {
        width: 100%;
        height: 503px;

        &__text {
            width: 400px;
            height: 70px;
            position: absolute;
            left: 0;
            top: 375px;

            color: #262626;
            font-family: 'Satoshi', sans-serif;
            font-size: 64px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: -2.56px;

            .icon-polygon {
                width: 34.329px;
                height: 34.329px;

                position: absolute;
                right: 0;
                top: 0;

                background: url('../../assets/images/polygon.svg') no-repeat;
            }
        }

        @media (max-width: 440px) {
            height: 259px;

            &__text {
                width: 288.665px;
                height: 35.001px;

                top: 192px;
                left: 0;

                font-size: 32px;
                letter-spacing: -0.64px;

                .icon-polygon {
                    width: 14.165px;
                    height: 14.165px;

                    background-size: contain;
                }
            }
        }
    }

    .content {
        width: 100%;
        height: auto;
        padding: 64px 0;

        &__text {
            height: auto;
            width: 955px;

            color: #000;
            font-family: 'Satoshi', sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;

            b {
                font-weight: 700;
            }
        }

        @media (max-width: 440px) {
            padding: 32px 0 64px 0;

            &__text {
                width: 100%;

                font-size: 16px;
                line-height: 140%;
            }
        }
    }

    .discuss {
        height: 334px;
        padding: 83px 0;

        .logo {
            width: 61px;
            height: 61px;
            margin: 0 auto;
            background: url('../../assets/images/logo.svg') no-repeat;
            background-size: contain;
        }

        h1,
        p,
        span {
            position: relative;
            font-family: 'Satoshi', sans-serif;
            font-style: normal;
        }

        h1,
        p {
            text-align: center;
        }

        h1,
        span {
            font-weight: 700;
        }

        h1 {
            height: 48px;
            margin: 22px 0 0 0;

            color: #262626;
            font-size: 40px;
            line-height: 48px;
            letter-spacing: -0.8px;
        }

        p {
            width: 613px;
            height: 54px;
            margin: 24.5px auto 64.39px auto;

            color: #666;
            font-size: 18px;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: -0.36px;
        }

        &__button {
            width: 245.27px;
            height: 24px;
            padding: 18px 0;

            margin: 0 auto;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 11px;

            border-radius: 48px;
            background: radial-gradient(70.71% 70.71% at 50% 50%, rgba(255, 255, 255, 0.12) 0%, rgba(0, 0, 0, 0.00) 100%), #000;

            cursor: pointer;

            span {
                width: 64px;
                height: 20px;

                color: #FFF;
                text-align: center;
                font-size: 17px;
                font-weight: 700;
                line-height: 20px;
            }

            .icon {
                width: 24px;
                height: 24px;

                background: url('../../assets/images/enter_white.svg') no-repeat;
            }
        }

        @media (max-width: 440px) {
            width: 343px;
            height: 433px;
            padding: 64px 0;

            .logo {
                width: 61px;
                height: 61px;
            }

            h1 {
                width: 100%;
                height: 96px;

                font-size: 32px;
                line-height: 120%;
                letter-spacing: -0.64px;
            }

            p {
                width: 329px;
                height: 104px;
                font-size: 16px;
                line-height: 150%;
            }

            &__button {
                width: 291px;
                height: 24.5px;
                padding: 18px 24px 18px 28px;

                span {
                    width: 62px;
                    height: 24px;
                    font-size: 16px;
                    line-height: 150%;
                    letter-spacing: 0.32px;
                }
            }
        }
    }

    @media (min-width: 768px) and (max-width: 1280px) {
        width: 960px;
    }

    @media (max-width: 440px) {
        width: 343px;
    }
}