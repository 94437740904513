.form-success {
    width: 100%;
    height: 594px;

    padding-top: 306px;

    .logo {
        width: 61px;
        height: 61px;
        margin: 0 auto;
        background: url('../../assets/images/logo.svg') no-repeat;
        background-size: contain;
    }

    h1,
    p,
    span {
        position: relative;
        font-family: 'Satoshi', sans-serif;
        font-style: normal;
    }

    h1,
    p {
        text-align: center;
    }

    h1,
    span {
        font-weight: 700;
    }

    h1 {
        height: 48px;
        margin: 22px 0 0 0;

        color: #262626;
        font-size: 40px;
        line-height: 48px;
        letter-spacing: -0.8px;
    }

    p {
        width: 613px;
        height: 36px;
        margin: 18.5px auto 51px auto;

        color: #666;
        font-size: 24px;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: -0.48px;
    }

    &__button {
        width: 255px;
        height: 20px;
        position: relative;

        margin: 0 auto;

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 11px;

        color: #262626;
        font-family: 'Satoshi', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 23px;

        cursor: pointer;

        .icon {
            width: 24px;
            height: 24px;
            position: relative;

            background: url('../../assets/images/evolve/button.svg') no-repeat;
        }
    }

    @media (max-width: 440px) {
        height: 463px;
        padding-top: 228px;

        .logo {
            width: 42.449px;
            height: 42.45px;
        }

        h1 {
            width: 100%;
            height: 27px;
            margin-top: 32px;
            font-size: 32px;
            line-height: 26.469px;
            letter-spacing: -1.28px;
        }

        p {
            width: 329px;
            height: 48px;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: -0.32px;
            margin: 32px auto 48px auto;
        }

        &__button {
            width: 201px;
            height: 20px;

            .icon {
                width: 19.708px;
                height: 19.708px;
                background-size: contain;
            }

            span {
                width: 180px;
                height: 17px;
                font-size: 13.96px;
                line-height: 18.423px;
            }
        }
    }
}