$mLeft: calc((100% - 1280px) / 2);
$mLeftMobile: calc((100% - 375px) / 2);
$mLeftPad: calc((100% - 960px) / 2);

.main {
    width: 1280px;
    position: relative;
    margin: 0 auto;

    @media (max-width: 440px) {
        width: 375px;

        section {
            overflow: hidden;
        }
    }

    @media (min-width: 768px) and (max-width: 1280px) {
        width: 960px;
    }


    .title {
        width: 100%;
        height: 669px;
        position: relative;
        margin: 0;

        &__text {
            width: 560px;
            height: 128px;
            position: relative;

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            h1 {
                margin: 0;
                font-family: 'Satoshi', sans-serif;
                font-size: 64px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                letter-spacing: -2.56px;
            }

            &-grey {
                color: #ADADAD;
            }

            &-black {
                color: #262626;
            }

            .polygon {
                width: 34.2px;
                height: 34.2px;
                position: absolute;
                right: -17px;
                top: 58px;

                background: url('../../assets/images/polygon.svg') no-repeat;
            }
        }

        &__container {
            width: 100%;
            height: 358px;
            position: absolute;
            left: 0;
            top: 311px;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &__btn-container {
            width: 1090px;
            height: 102px;
            display: flex;
            flex-wrap: wrap;
            gap: 6.35px;
        }

        &__button {
            width: auto;
            height: 24px;
            padding: 12px 14px;
            position: relative;

            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;

            color: #292929;
            text-shadow: 0px 0.499px 15.97px rgba(8, 24, 93, 0.25);
            font-family: 'Satoshi', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            cursor: pointer;

            border-radius: 7.985px;
            background: #F7F7F9;

            .icon {
                width: 24px;
                height: 24px;
            }
        }

        @media (min-width: 768px) and (max-width: 1280px) {
            height: 592px;

            &__container {
                height: 291px;
                top: 255px;
            }

            &__btn-container {
                width: 960px;
                height: 89px;
            }

            &__button {
                padding: 10.4px 12.2px;
                gap: 6.94px;
                font-size: 13.912px;

                .icon {
                    width: 20.83px;
                    height: 20.83px;
                }
            }
        }

        @media (max-width: 440px) {
            width: 100vw;
            height: 429px;

            margin-left: calc((100vw - 375px) / 2 * -1);

            &__container {
                height: 228px;
                top: 192px;

                .marquee-container {
                    height: 112px;

                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }
            }

            &__text {
                width: 280px;
                height: 64px;

                margin-left: calc((100vw - 375px) / 2 + 16px);

                h1 {
                    font-size: 32px;
                    font-weight: 700;
                    line-height: 100%;
                    letter-spacing: -1.28px;
                }

                .polygon {
                    width: 17.165px;
                    height: 17.165px;

                    top: 29px;

                    background-size: contain;
                }
            }

            &__btn-container {
                width: 627px;
                height: 109px;
            }

            &__button {
                height: 16px;
                padding: 8px 12px;

                margin-right: 6.35px;

                font-size: 12px;

                .icon {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }

    .projects,
    .industrial-design {
        width: 100%;
        height: auto;

        .btn-all-cases {
            width: 198px;
            height: 24px;
            padding: 18px 28px 18px 24px;

            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 11px;
            align-items: center;

            margin-top: 64px;
            margin-left: calc(100% - 250px);

            text-align: right;
            border-radius: 48px;
            background: radial-gradient(70.71% 70.71% at 50% 50%, rgba(255, 255, 255, 0.12) 0%, rgba(0, 0, 0, 0.00) 100%), #000;

            cursor: pointer;

            span {
                color: #FFF;
                text-align: center;
                font-family: 'Satoshi', sans-serif;
                font-size: 17px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
            }

            .icon {
                width: 24px;
                height: 24px;

                background: url('../../assets/images/arr.svg') no-repeat;
            }

            @media (max-width: 440px) {
                width: 143px;
                height: 17.3px;
                padding: 13px 20.2px 13px 17.3px;

                margin-left: calc(100% - 180px);
                margin-top: 32px;

                span {
                    font-size: 12.24px;
                    line-height: 14.4px;
                }

                .icon {
                    width: 17.28px;
                    height: 17.28px;
                    background-size: contain;
                }
            }
        }

        h1 {
            color: #262626;
            font-family: 'Satoshi', sans-serif;
            font-size: 64px;
            font-style: normal;
            font-weight: 700;
            line-height: 110%;
            letter-spacing: -1.28px;

            margin: 0 0 32px 0;
        }

        &__card-container {
            width: 100%;
            height: auto;

            display: flex;
            flex-wrap: wrap;
            gap: 16px;
        }

        @media (min-width: 768px) and (max-width: 1280px) {
            &__card-container {
                gap: 8px;
            }
        }

        @media (max-width: 440px) {
            width: calc(100% - 16px);

            h1 {
                font-size: 32px;
                letter-spacing: -0.64px;
            }

            &__card-container {
                gap: 16px;
            }
        }
    }

    .projects {
        padding: 64px 0;

        @media (max-width: 440px) {
            width: calc(100% - 32px);
            padding: 64px 16px;
        }
    }

    .industrial-design {
        padding: 64px 0 16px 0;

        @media (max-width: 440px) {
            width: calc(100% - 32px);
            padding: 64px 16px;
        }
    }

    .about {
        width: 100%;
        height: 285px;
        position: relative;
        padding: 64px 0;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h1 {
            width: auto;
            height: 15px;
            position: relative;
            margin: 0;

            color: #A6A6A6;
            font-family: 'Satoshi', sans-serif;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%;
            letter-spacing: 0.75px;
            text-transform: uppercase;
        }

        p {
            width: 632px;
            height: 238px;
            position: relative;
            margin: 0;

            color: #000;
            font-family: 'Satoshi', sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
        }

        &__buttons {
            width: 308px;
            height: 112px;
            position: absolute;
            right: 0;
            top: 64px;

            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            gap: 16px;
        }

        &__button {
            width: auto;
            height: 24px;
            position: relative;

            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            gap: 8px;

            cursor: pointer;

            img {
                width: 24px;
                height: 24px;
            }

            h5 {
                width: auto;
                height: 24px;
                position: relative;
                margin: 0;

                color: #010101;
                text-shadow: 0px 1px 32px rgba(8, 24, 93, 0.25);
                font-family: 'Satoshi', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%;
                letter-spacing: 0.32px;
            }
        }

        @media (min-width: 768px) and (max-width: 1280px) {
            p {
                width: 395px;
                font-size: 16px;
            }
        }

        @media (max-width: 440px) {
            width: calc(100% - 32px);
            height: 370px;
            padding: 64px 16px;

            justify-content: flex-start;
            gap: 32px;

            h1 {
                height: 12px;
                font-size: 12px;
                letter-spacing: 0.6px;
            }

            p {
                width: 343px;
                height: 198px;
                font-size: 16px;
            }

            &__buttons {
                height: 96px;
                top: 338px;
                left: 16px;
                justify-content: space-between;
            }

            &__button {
                padding: 8px 8px 8px 0;
            }
        }
    }

    .full-size {
        width: 100vw;
        margin-left: calc(calc(calc(100vw - 1280px) / 2) * -1);
        background: #000;

        @media (max-width: 440px) {
            overflow: hidden;
            // width: calc(100vw + 1px);
            margin-left: calc(calc(calc(100vw - 375px) / 2) * -1);
        }

        @media (min-width: 768px) and (max-width: 1280px) {
            margin-left: calc(calc(calc(100vw - 960px) / 2) * -1);
        }
    }

    .deliver {
        height: 1277px;
        position: relative;

        background: #000;

        &__container {
            width: 1280px;
            height: 1027px;
            position: relative;
            margin: 0 auto;

            &__title {
                width: 690px;
                height: 106px;
                position: absolute;
                left: 0;
                top: 164px;

                display: flex;
                flex-direction: column;
                justify-content: space-between;

                h1,
                p {
                    font-family: 'Satoshi', sans-serif;
                    font-style: normal;
                    line-height: 100%;
                    margin: 0;
                }

                h1 {
                    height: 64px;
                    color: #FFF;
                    font-size: 64px;
                    font-weight: 700;
                    letter-spacing: -2.56px;
                }

                p {
                    height: 24px;
                    color: #666;
                    font-size: 24px;
                    font-weight: 500;
                    letter-spacing: -0.48px;
                }
            }

            &__categories {
                width: 100%;
                height: 632px;
                position: absolute;
                left: 0;
                top: 340px;

                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                gap: 16px;
            }

            &__category {
                width: 252px;
                height: 234px;
                position: relative;
                padding: 37px 28px;

                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 10px;

                border-radius: 28px;
                background: #222327;

                h3,
                p {
                    position: relative;
                    font-family: 'Satoshi', sans-serif;
                    font-style: normal;
                    margin: 0;
                }

                h3 {
                    height: auto;
                    width: auto;
                    max-width: 216px;

                    color: #FFF;
                    font-size: 32px;
                    font-weight: 700;
                    line-height: 100%;
                    letter-spacing: -1.28px;
                }

                p {
                    width: 253px;
                    height: auto;

                    color: #666;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 115%;
                    letter-spacing: -0.72px;
                }
            }
        }

        &__stat-header {
            width: 100%;
            height: 122px;
            position: relative;
            background: #FFF;
            padding: 64px 0;

            &__counter-container {
                width: 1280px;
                height: 122px;
                position: relative;
                margin: 0 auto;

                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }

            &__counter {
                width: 308px;
                height: 122px;
                position: relative;

                display: flex;
                flex-direction: column;
                justify-content: space-between;

                h2,
                p {
                    color: #595959;
                    font-family: 'Satoshi', sans-serif;
                    font-style: normal;
                }

                h2 {
                    height: 70px;
                    width: auto;
                    position: relative;
                    margin: 0;

                    font-weight: 700;
                    font-size: 64px;
                    line-height: 110%;
                    letter-spacing: -1.28px;
                }

                p {
                    height: 36px;
                    width: auto;
                    position: relative;
                    margin: 0;

                    font-weight: 500;
                    font-size: 24px;
                    line-height: 150%;
                    letter-spacing: -0.48px;
                }
            }
        }

        @media (min-width: 768px) and (max-width: 1280px) {
            height: 1070px;

            &__container {
                width: 960px;
                height: 660px;

                &__title {
                    height: 88px;
                    top: 96px;

                    h1 {
                        font-size: 48px;
                        letter-spacing: -1.92px;
                    }
                }

                &__categories {
                    width: 960px;
                    height: 508px;
                    top: 248px;
                    gap: 8px;
                }

                &__category {
                    width: 194px;
                    height: 202px;
                    padding: 24px 20px;

                    h3 {
                        max-width: 174px;
                        font-size: 24px;
                        letter-spacing: -0.96px;
                    }

                    p {
                        width: 194px;
                        font-size: 16px;
                        letter-spacing: -0.64px;
                    }
                }
            }

            &__stat-header {
                &__counter-container {
                    width: 960px;
                }
            }
        }

        @media (max-width: 440px) {
            height: 795px;

            &__stat-header {
                width: 100%;
                height: 156px;
                padding: 64px 0;
                border-radius: 0px 0px 24px 24px;

                &__counter-container {
                    width: 343px;
                    height: 156px;
                    flex-wrap: wrap;
                    gap: 32px 0;
                }

                &__counter {
                    width: 148px;
                    height: 62px;
                    padding: 0 0 0 16px;

                    h2 {
                        color: #666;
                        font-size: 24px;
                        line-height: 100%;
                        letter-spacing: -0.48px;
                    }

                    p {
                        color: #000;
                        font-size: 16px;
                        line-height: 140%;
                    }
                }
            }

            &__container {
                width: 375px;
                height: 383px;

                padding: 64px 0;

                &__title {
                    width: 343px;
                    height: 84px;

                    left: 16px;
                    top: 64px;

                    h1 {
                        font-size: 32px;
                        letter-spacing: -0.64px;
                    }

                    p {
                        width: 250px;
                        height: 44px;
                        font-size: 16px;
                        line-height: 140%;
                    }
                }

                &__categories {
                    width: 2242px;
                    height: 267px;
                    left: 16px;
                    top: 180px;

                    flex-direction: row;
                    flex-wrap: nowrap;
                    gap: 14px;
                }

                &__carousel {
                    margin-left: 16px;
                    height: 267px;

                    margin-top: 116px;

                    overflow: visible;

                    .carousel-slider,
                    .slider,
                    .carousel,
                    .slider-wrapper {
                        height: 267px;
                        overflow: visible;
                    }
                }

                &__category {
                    width: 267px;
                    height: 267px;
                    // padding: 32.2px 24.4px;

                    border-radius: 24.373px;

                    h3,
                    p {
                        text-align: left;
                    }

                    h3 {
                        font-size: 27.855px;
                        letter-spacing: -1.114px;
                    }

                    p {
                        color: #BFBFBF;
                        width: 220.231px;
                        font-size: 16px;
                        line-height: 115%;
                    }
                }
            }
        }
    }

    .dev-process {
        height: 756px;
        position: relative;

        &__content {
            width: 1280px;
            height: 617px;
            position: relative;
            margin: 0 auto;
        }

        &__stage-container {
            width: 100%;
            height: 294px;
            position: absolute;
            top: 333px;
            left: 0;

            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        &__line {
            width: 915px;
            height: 1px;
            position: absolute;
            left: 85px;
            top: 32px;

            background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
        }

        &__stage {
            width: 290px;
            height: 294px;
            position: relative;

            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            gap: 16px;

            &__icon {
                width: 64px;
                height: 64px;
                position: absolute;
                left: 0;
                top: 0;
            }

            h2,
            p {
                position: relative;
                margin: 0;
                font-family: 'Satoshi', sans-serif;
                font-style: normal;
            }

            h2 {
                height: 32px;
                width: auto;

                color: #FFF;
                font-size: 32px;
                font-weight: 700;
                line-height: 100%;
                letter-spacing: -1.28px;
            }

            p {
                height: 150px;
                width: 290px;

                color: #666;
                font-size: 18px;
                font-weight: 400;
                line-height: 140%;
                letter-spacing: -0.36px;
            }
        }

        h1,
        span {
            position: absolute;
            left: 0;
            margin: 0;

            font-family: 'Satoshi', sans-serif;
            font-style: normal;
            line-height: 100%;
        }

        h1 {
            top: 120px;
            color: #FFF;
            font-size: 64px;
            font-weight: 700;
            letter-spacing: -2.56px;
        }

        span {
            top: 202px;
            color: #666;
            font-size: 24px;
            font-weight: 500;
            letter-spacing: -0.96px;
        }

        @media (min-width: 768px) and (max-width: 1280px) {
            height: 531px;

            &__content {
                width: 960px;
                height: 403px;
                padding-top: 64px;
            }

            h1 {
                top: 64px;
                font-size: 48px;
                letter-spacing: -1.92px;
            }

            span {
                top: 128px;
                line-height: 100%;
                letter-spacing: -0.48px;
            }

            &__line {
                top: 24px;
                left: 0;
                width: 761px;
            }

            &__stage-container {
                top: 216px;
                height: 251px;
            }

            &__stage {
                width: 231px;
                height: 251px;
                gap: 12.3px;

                &__icon {
                    width: 49.2px;
                    height: 49.2px;
                }

                h2 {
                    height: 18.45px;
                    font-size: 18.45px;
                    line-height: 100%;
                    letter-spacing: -0.738px;
                }

                p {
                    width: 231px;
                    height: 147px;
                    font-size: 15px;
                    line-height: 140%;
                    letter-spacing: -0.3px;
                }
            }
        }

        @media (max-width: 440px) {
            height: 997px;
            padding: 64px 0;

            &__content {
                width: 375px;
                height: 100%;
            }

            h1 {
                height: 35px;
                top: 0;
                left: 16px;
                line-height: 110%;
                letter-spacing: -0.64px;
                font-size: 32px;
            }

            span {
                top: 43px;
                left: 16px;
                height: 22px;
                font-size: 16px;
                line-height: 140%;
            }

            &__stage-container {
                left: 16px;
                top: 97px;
                width: 343px;
                height: 900px;
                flex-direction: column;
            }

            &__stage {
                width: 343px;
                padding-top: 8px;
                height: auto;
                align-items: end;

                h2 {
                    width: 247px;
                    height: 24px;
                    font-size: 24px;
                    line-height: 100%;
                    letter-spacing: -0.48px;
                }

                p {
                    width: 247px;
                    height: auto;
                    font-size: 16px;
                    line-height: 140%;
                }
            }

            &__line {
                width: 1px;
                height: 696px;
                left: 32px;
                top: 0;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
            }
        }
    }

    .stack {
        height: 620px;
        padding: 104px 0;

        &__column {
            position: absolute;

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &__point {
                width: 430px;
                height: auto;

                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 8px;
            }

            h2,
            p {
                margin: 0;
            }

            h2 {
                width: auto;
                height: 25px;

                color: #FFF;
                font-family: 'Satoshi', sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;
                letter-spacing: -0.36px;
            }

            p {
                color: #ADADAD;
                font-family: 'Satoshi', sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                letter-spacing: -0.36px;
            }
        }

        #stack-title {
            width: 512px;
            height: 221px;
            left: 0;
            top: 203px;

            p {
                width: 100%;
                height: 125px;
                color: #666;
            }
        }

        #stack-points {
            width: 430px;
            height: 348px;
            right: 92px;
            bottom: 15px;
        }

        &__content {
            width: 1280px;
            height: 100%;
            position: relative;
            margin: 0 auto;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        h1 {
            width: auto;
            height: 64px;
            margin: 0;

            color: #FFF;
            font-family: 'Satoshi', sans-serif;
            font-size: 64px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: -2.56px;
            text-align: left;
        }

        &__icons {
            width: 647px;
            height: 647px;
            position: absolute;
            right: 0;
            top: 0;
            margin: 0;

            background: url('../../assets/images/stack.png') no-repeat;
            background-size: contain;
            // background-size: 1311px 403px;
            // background-position: -16px -14px;
        }

        @media (min-width: 768px) and (max-width: 1280px) {
            padding: 64px 0;
            height: 589px;

            &__content {
                width: 960px;
            }

            &__column {
                p {
                    font-size: 16px;
                    letter-spacing: -0.32px;
                }
            }

            h1 {
                height: 48px;
                font-size: 48px;
                letter-spacing: -1.92px;
            }

            #stack-title {
                width: 320px;
                height: 234px;

                p {
                    height: 154px;
                }
            }

            &__icons {
                width: 589px;
                height: 589px;
            }
        }

        @media (max-width: 440px) {
            height: 554px;
            padding: 64px 0;

            &__content {
                height: 100%;
                width: 343px;

                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 32px;

            }

            #stack-title {
                height: 202px;
                width: 100%;
                position: relative;
                top: 0;

                p {
                    width: 100%;
                    height: 154px;
                }
            }

            #stack-points {
                width: 100%;
                height: 322px;

                p {
                    width: 100%;
                    height: auto;
                }
            }

            &__icons {
                width: 343px;
                height: 279px;
                position: relative;

                padding: 20.5px 0;
                overflow: hidden;

                border-radius: 44px;
                background: #18181B;

                // // margin-top: 14px;

                // background: url('../../assets/images/stack-mobile.png') no-repeat;
                // background-size: contain;
            }

            &__column {
                width: 100%;
                position: relative;
                left: 0;
                top: 0;

                &__point {
                    width: 100%;
                    // height: auto;
                }

                &__point:nth-child(0) {
                    height: 112px;

                    p {
                        height: 88px;
                    }
                }

                &__point:nth-child(1) {
                    height: 178px;

                    p {
                        height: 154px;
                    }
                }

                h1 {
                    height: 32px;
                    font-size: 32px;
                    line-height: 100%;
                    letter-spacing: -0.64px;
                }

                h2 {
                    height: 16px;
                    font-size: 16px;
                    line-height: 100%;
                    letter-spacing: -0.32px;
                }

                p {
                    width: 100%;
                    font-size: 16px;
                    line-height: 140%;
                }
            }
        }
    }

    .perfectionism {
        height: 481px;
        padding: 109px 0 57px 0;

        &__content {
            width: 1280px;
            height: 100%;
            position: relative;
            margin: 0 auto;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &__category-container {
            width: 100%;
            height: 182px;
            position: relative;

            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        &__category {
            width: 375px;
            height: 234px;
            position: relative;

            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 23px;

            h2,
            p {
                position: relative;
                margin: 0;
                font-family: 'Satoshi', sans-serif;
                font-style: normal;
            }

            h2 {
                color: #FFF;
                font-size: 36px;
                font-weight: 700;
                line-height: 100%;
                letter-spacing: -1.44px;
            }

            p {
                height: 175px;
                color: #666;
                font-size: 18px;
                font-weight: 400;
                line-height: 140%;
                letter-spacing: -0.36px;
            }
        }

        h1 {
            height: 128px;
            width: 914px;
            margin: 0;

            color: #FFF;
            font-family: 'Satoshi', sans-serif;
            font-size: 64px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: -2.56px;

            b {
                color: #ADADAD;
            }
        }

        @media (min-width: 768px) and (max-width: 1280px) {
            height: 375px;
            padding: 64px 0;

            &__content {
                width: 960px;

                h1 {
                    height: 96px;
                    font-size: 48px;
                    letter-spacing: -1.92px;
                }
            }

            &__category-container {
                height: 230px;
            }

            &__category {
                width: 299px;
                height: 230px;
                gap: 23px;

                h2 {
                    font-size: 24px;
                    letter-spacing: -0.96px;
                }

                p {
                    height: 198px;
                    font-size: 15px;
                    letter-spacing: -0.32px;
                }
            }
        }

        @media (max-width: 440px) {
            height: 376px;
            padding: 64px 0;

            &__content {
                width: 343px;
                height: 100%;
            }

            &__category {
                width: 100%;
                height: 216px;

                gap: 16px;

                h2 {
                    height: 24px;
                    font-size: 24px;
                    line-height: 100%;
                    letter-spacing: -0.48px;
                }

                p {
                    width: 96%;
                    height: 176px;
                    font-size: 16px;
                    line-height: 140%;
                }
            }

            &__category-container {
                width: 100%;
                height: 216px;
            }

            &__category:nth-child(3) {
                display: none;
            }

            &__category:nth-child(2) {
                display: none;
            }

            h1 {
                width: 343px;
                height: 96px;
                font-size: 32px;
                line-height: 100%;
                letter-spacing: -0.64px;
            }
        }
    }

    .trusted {
        height: 285px;
        padding-top: 120px;
        position: relative;

        h1 {
            height: 30px;
            position: relative;
            margin: 0 0 47px $mLeft;

            color: #FFF;
            text-align: left;
            font-family: 'Satoshi', sans-serif;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 30px;
        }

        &__block {
            width: 100%;
            height: 96px;
            padding: 56px 0;
            border-radius: 50px 50px 0px 0px;
            background: #FFF;

            &__logos {
                width: 2120px;
                height: 96px;
                margin: 0 auto;

                display: flex;
                flex-direction: row;
                align-items: center;
            }

            &__logo {
                width: auto;
                position: relative;
            }
        }

        @media (min-width: 768px) and (max-width: 1280px) {
            height: 258px;
            padding-top: 64px;

            h1 {
                font-size: 24px;
                line-height: 30px;
                margin: 0 0 47px $mLeftPad;
            }

            &__block {
                height: 110px;
                padding: 36px 0;

                &__logos {
                    height: 110px;
                }
            }
        }

        @media (max-width: 440px) {
            height: 152px;
            padding: 64px 0 32px 0;

            h1 {
                height: 24px;
                font-size: 24px;
                line-height: 100%;
                letter-spacing: -0.48px;

                margin: 0 0 32px calc($mLeftMobile + 16px);
            }

            &__block {
                width: 100%;
                height: 96px;
                border-radius: 24px 24px 0 0;
                padding-bottom: 0;
                padding: 32px 0;

                &__logos {
                    width: 838px;
                    height: 64px;
                    gap: 32px;
                    margin: 0 0 0 calc(calc((100% - 375px) / 2) + 16px);
                }

                #wallet-logo {
                    height: 32.5px;
                }

                #rada-logo {
                    height: 21.11px;
                }

                &__logo {
                    max-height: 64px;
                    margin-right: 32px;
                }
            }
        }
    }

    .evolve {
        height: 660px;
        padding: 64px 0;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h1 {
            width: 630px;
            height: 128px;
            position: relative;
            margin: 0;

            color: #262626;
            font-feature-settings: 'liga' off;
            font-family: 'Satoshi', sans-serif;
            font-size: 64px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: -4px;

            b {
                color: #ADADAD;
            }
        }

        &__category-container {
            width: 100%;
            height: 468px;

            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        &__category {
            width: 357px;
            height: 394px;
            position: relative;
            padding: 37px 28px;

            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 14px;

            border-radius: 18px;
            border: 1px solid rgba(0, 0, 0, 0.25);

            &__icon {
                width: 45px;
                height: 45px;
                position: relative;
            }

            &__content {
                width: 100%;
                height: 271px;

                h2,
                h4,
                li {
                    position: relative;
                    margin: 0;
                    font-family: 'Satoshi', sans-serif;
                    font-style: normal;
                }

                h2,
                h4 {
                    color: #262626;
                    font-weight: 700;
                }

                h4,
                li {
                    font-size: 18px;
                }

                h2 {
                    height: 32px;

                    font-size: 32px;
                    line-height: 100%;
                    letter-spacing: -1.28px;
                    margin-bottom: 74px;
                }

                h4 {
                    height: 21px;

                    line-height: 115%;
                    letter-spacing: -0.72px;
                }

                li {
                    color: #666;
                    font-weight: 300;
                    line-height: 150%;
                    letter-spacing: -0.36px;
                }

                ol {
                    padding-left: 33px;

                    list-style-type: disc;
                    margin-top: 15px;
                }
            }

            &__button {
                width: auto;
                height: 24px;
                position: relative;

                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                gap: 10px;

                color: #262626;
                font-family: 'Satoshi', sans-serif;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                letter-spacing: -0.96px;

                cursor: pointer;

                .icon {
                    width: 24px;
                    height: 24px;
                    position: relative;

                    background: url('../../assets/images/evolve/button.svg') no-repeat;
                }
            }
        }

        @media (min-width: 768px) and (max-width: 1280px) {
            height: 628px;
            padding: 64px 0;

            h1 {
                height: 96px;
                font-size: 48px;
            }

            &__category-container {
                width: 100%;
                height: 420px;
            }

            &__category {
                width: 267px;
                height: 372px;
                padding: 24px;
                gap: 16px;

                &__content {
                    h2 {
                        height: 24px;
                        font-size: 24px;
                        letter-spacing: -0.96px;
                    }

                    h4 {
                        height: 16px;
                        font-size: 16px;
                        letter-spacing: -0.64px;
                    }

                    li {
                        font-size: 16px;
                        letter-spacing: -0.32px;
                    }

                    ol {
                        padding-left: 24px;
                    }
                }

                &__button {
                    height: 24px;
                    gap: 8px;
                    line-height: 130%;
                    font-size: 18px;
                    letter-spacing: -0.72px;
                }
            }
        }

        @media (max-width: 440px) {
            width: 100vw;
            height: 463px;
            padding: 64px 0;
            margin-left: calc(calc(100vw - 375px) / 2 * -1);

            h1 {
                margin-left: calc(calc(100vw - 375px) / 2 + 16px);

                width: 343px;
                height: 64px;
                font-size: 32px;
                line-height: 100%;
                letter-spacing: -0.64px;
            }

            &__category-container {
                width: 1045px;
                height: 367px;
            }

            &__carousel {
                width: 351px;
                // width: calc(100vw - 16px);
                margin-left: calc(calc(100vw - 375px) / 2 + 16px);
                height: 367px;

                // margin-top: 116px;

                overflow: visible;

                .slide {
                    img {
                        width: 32px;
                    }
                }

                .carousel-slider,
                .slider,
                .carousel,
                .slider-wrapper {
                    height: 367px;
                    overflow: visible;
                }
            }

            &__category {
                width: 343px;
                height: 367px;

                padding: 24px;

                &__icon {
                    width: 32px;
                    height: 32px;
                }

                &__content {
                    width: 100%;
                    height: 240px;

                    h2,
                    h4,
                    ol {
                        text-align: left;
                    }

                    h2 {
                        height: 24px;
                        font-size: 24px;
                        line-height: 100%;
                        letter-spacing: -0.48px;
                    }

                    h4 {
                        height: 16px;
                        font-size: 16px;
                        line-height: 100%;
                        letter-spacing: -0.32px;
                    }

                    ol {
                        padding-left: 14px;
                    }

                    li {
                        font-size: 16px;
                    }
                }

                &__button {
                    height: 24px;
                    align-items: center;
                    font-size: 16px;
                    line-height: 100%;
                    letter-spacing: -0.32px;

                    .icon {
                        width: 24px;
                        height: 24px;

                    }
                }
            }
        }
    }

    .tech-team {
        height: 916px;
        padding: 64px 0;

        &__title {
            width: 660px;
            height: 152px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin: 0 auto;

            h1,
            p {
                text-align: center;
                font-family: 'Satoshi', sans-serif;
                font-style: normal;
                margin: 0;
            }

            p {
                width: 100%;
                height: 55px;

                color: #797878;
                font-size: 18px;
                font-weight: 300;
                line-height: 27.218px;
                /* 151.209% */
            }

            h1 {
                font-size: 64px;
                font-weight: 700;
                line-height: normal;
                letter-spacing: -2.56px;
                color: #262626;
            }
        }

        &__period {
            width: 192px;
            height: 36.6px;
            padding: 6.4px;

            display: flex;
            flex-direction: row;
            justify-content: space-between;

            border-radius: 11.207px;
            border: 1px solid rgba(0, 0, 0, 0.05);
            box-shadow: 0px 0.801px 4.003px 0px rgba(86, 110, 232, 0.10) inset;

            margin: 22.41px auto 0 auto;

            &__item {
                width: 96px;
                height: 36px;
                margin: 0;
                color: #797878;
                font-family: 'Satoshi', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 32.021px;
                text-align: center;
                border-radius: 9.606px;

                cursor: pointer;

                &__active {
                    color: #262626;
                    border: 1px solid rgba(0, 0, 0, 0.05);
                    box-shadow: 0px 4.803px 28.819px 0px rgba(181, 194, 251, 0.10);
                }
            }
        }

        &__plan-container {
            width: 100%;
            height: 653px;

            margin: 54px auto 0 auto;

            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        &__plan {
            width: 349px;
            height: 589px;
            padding: 32px;

            border-radius: 18px;
            border: 1px solid rgba(0, 0, 0, 0.15);

            h2 {
                margin: 0;
                margin-bottom: 11.2px;
                color: #262626;
                font-family: 'Satoshi', sans-serif;
                font-size: 35.223px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                letter-spacing: -0.801px;
            }

            p {
                margin: 0;
                margin-bottom: 19px;
                color: #797878;
                font-family: 'Satoshi', sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 300;
                line-height: 25.617px;
            }

            .price {
                height: 65px;
                width: auto;

                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                gap: 7px;

                span {
                    color: #262626;
                    font-family: 'Satoshi', sans-serif;
                    font-size: 48px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    letter-spacing: -0.801px;

                    margin: 0;
                }

                p {
                    color: #797878;
                    font-family: 'Satoshi', sans-serif;
                    font-size: 19.212px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;

                    margin: 0;
                    margin-top: 31px;
                }
            }

            .icon {
                width: 45px;
                height: 45px;
                margin: 0;
                margin-bottom: 16px;
                background-size: cover;
            }

            #plant {
                background: url('../../assets/images/team/plant.svg');
            }

            #rocket {
                background: url('../../assets/images/team/rocket.svg');
            }

            #business {
                background: url('../../assets/images/team/business.svg');
            }

            &__perks {
                width: 349px;
                height: 203px;

                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 9.61px;

                &__item {
                    width: 100%;
                    height: 33px;

                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-direction: row;
                    gap: 12.81px;

                    color: #1B223C;
                    font-family: 'Satoshi', sans-serif;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 32.021px;

                    .icon-check {
                        width: 22.4px;
                        height: 22.4px;
                        background: url('../../assets/images/team/check.svg');
                        background-size: contain;
                    }
                }

                margin-top: 44px;
                margin-bottom: 35px;
            }

            .btn-start {
                width: 279px;
                height: 14px;
                padding: 17.611px 35.223px;

                border-radius: 9.606px;
                border: 0.801px solid rgba(0, 0, 0, 0.15);

                color: #262626;
                font-family: 'Satoshi', sans-serif;
                font-size: 19.212px;
                font-style: normal;
                font-weight: 500;
                line-height: 12.021px;
                text-align: center;
                cursor: pointer;

                margin: 0;
            }
        }

        @media (min-width: 768px) and (max-width: 1280px) {
            width: 100%;
            height: 941px;

            &__title {
                height: 131px;

                h1 {
                    font-size: 48px;
                }
            }

            &__plan-container {
                width: 960px;
                height: 670px;

                margin: 48px auto 0 auto;
            }

            &__plan {
                width: 251px;
                height: 573px;

                h2 {
                    font-size: 24px;
                    letter-spacing: -0.801px;
                }

                p {
                    font-size: 16px;
                }

                &__perks {
                    &__item {
                        font-size: 16px;
                    }
                }

                .btn-start {
                    width: 180px;
                    height: 14px;
                }
            }
        }

        @media(max-width: 440px) {
            width: 100%;
            height: 676px;

            &__title {
                width: 343px;
                height: 92px;

                h1 {
                    font-size: 32px;
                    line-height: 100%;
                    letter-spacing: -0.64px;
                    text-align: left;
                }

                p {
                    height: 44px;
                    font-size: 16px;
                    line-height: 140%;
                    text-align: left;
                }
            }

            &__period {
                display: none;
            }

            &__plan-container {
                margin-top: 32px;
            }

            &__carousel {
                width: 335px;
                height: 551px;
                margin-top: 32px;
                margin-left: calc($mLeftMobile + 16px);

                overflow: visible;

                // .slide {
                //     img {
                //         width: 32px;
                //     }
                // }

                .carousel-slider,
                .slider,
                .carousel,
                .slider-wrapper {
                    height: 551px;
                    overflow: visible;
                }
            }

            &__plan {
                width: 327px;
                height: 551px;
                padding: 24px;

                h2 {
                    text-align: left;
                    font-size: 24px;
                    line-height: 100%;
                    letter-spacing: -0.48px;
                }

                p {
                    text-align: left;
                    font-size: 16px;
                    line-height: 140%;
                }

                .price {
                    height: 32px;
                    gap: 8px;

                    span {
                        font-size: 24px;
                        line-height: normal;
                        letter-spacing: -0.801px;
                    }

                    p {
                        font-size: 19.212px;
                        line-height: normal;
                        margin-top: 5px;
                    }
                }

                &__perks {
                    width: 279px;
                    height: 213px;
                    gap: 12px;

                    margin-top: 32px;
                    margin-bottom: 16px;

                    &__item {
                        font-size: 16px;
                        line-height: 32.021px;
                    }
                }
            }

            .btn-start {
                width: 279px;
                height: 51px;
                padding: 17.6px 35.2px;

                font-size: 16px;
                font-weight: 700;
                line-height: 100%;
                letter-spacing: -0.32px;
            }
        }
    }

    .social-media {
        height: 561px;
        padding: 64px 0;
        background: #fff;

        &__container {
            width: 1280px;
            height: 100%;
            position: relative;
            margin: 0 auto;

            h1,
            p {
                position: relative;
                color: #262626;
                font-family: 'Satoshi', sans-serif;
                font-style: normal;
                margin: 0;
            }

            h1 {
                width: 650px;
                height: 128px;

                font-feature-settings: 'liga' off;
                font-size: 64px;
                font-weight: 700;
                line-height: 100%;
                letter-spacing: -4px;

                b {
                    color: #ADADAD;
                }
            }

            p {
                width: 582px;
                height: 102px;
                margin-top: 32px;

                font-size: 24px;
                font-weight: 500;
                line-height: 140%;
            }

        }

        &__posts {
            width: auto;
            height: 369px;
            position: absolute;
            bottom: 0;
            left: 0;

            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            gap: 32px;
        }

        &__post {
            width: 361px;
            height: 369px;
            position: relative;
            border-radius: 8px;
            background: rgba(32, 32, 32, 0.06);
            border: none;
        }

        @media (min-width: 768px) and (max-width: 1280px) {
            height: 529px;
            padding: 64px 0;

            &__container {
                width: 960px;
                height: 681px;

                h1 {
                    width: 450px;
                    height: 96px;

                    font-size: 48px;
                    letter-spacing: -4px;
                }

                p {
                    margin-top: 32px;
                    width: 632px;
                    height: 102px;
                    letter-spacing: -0.48px;
                }
            }

            &__posts {}
        }

        @media (max-width: 440px) {
            width: 100vw;
            margin-left: calc(calc(100vw - 375px) / 2 * -1);
            height: 524px;
            padding: 64px 0;

            &__container {
                width: 343px;
                height: 100%;

                h1 {
                    width: 350px;
                    height: 64px;

                    font-size: 32px;
                    line-height: 100%;
                    letter-spacing: -0.64px;
                }

                p {
                    width: 294px;
                    height: 88px;
                    margin-top: 16px;

                    font-size: 16px;
                    line-height: 140%;
                }
            }

            &__post {
                width: 316.976px;
                height: 324px;
                display: block;
                position: relative;
            }

            &__carousel {
                width: 334px;
                // width: calc(100vw - 16px);
                height: 324px;
                margin-top: 32px;

                overflow: visible;

                .carousel-slider,
                .slider,
                .carousel,
                .slider-wrapper {
                    height: 324px;
                    overflow: visible;
                }

                .slide {

                    // min-width: 316px;
                    // height: 324px;

                    div {
                        width: 316.976px;
                    }

                    // margin-right: 16px;
                }
            }
        }
    }

    .discuss {
        height: 334px;
        padding: 83px 0;

        .logo {
            width: 61px;
            height: 61px;
            margin: 0 auto;
            background: url('../../assets/images/logo.svg') no-repeat;
            background-size: contain;
        }

        h1,
        p,
        span {
            position: relative;
            font-family: 'Satoshi', sans-serif;
            font-style: normal;
        }

        h1,
        p {
            text-align: center;
        }

        h1,
        span {
            font-weight: 700;
        }

        h1 {
            height: 48px;
            margin: 22px 0 0 0;

            color: #262626;
            font-size: 40px;
            line-height: 48px;
            letter-spacing: -0.8px;
        }

        p {
            width: 613px;
            height: 54px;
            margin: 24.5px auto 64.39px auto;

            color: #666;
            font-size: 18px;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: -0.36px;
        }

        &__button {
            width: 245.27px;
            height: 24px;
            padding: 18px 0;

            margin: 0 auto;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 11px;

            border-radius: 48px;
            background: radial-gradient(70.71% 70.71% at 50% 50%, rgba(255, 255, 255, 0.12) 0%, rgba(0, 0, 0, 0.00) 100%), #000;

            cursor: pointer;

            span {
                width: 64px;
                height: 20px;

                color: #FFF;
                text-align: center;
                font-size: 17px;
                font-weight: 700;
                line-height: 20px;
            }

            .icon {
                width: 24px;
                height: 24px;

                background: url('../../assets/images/enter_white.svg') no-repeat;
            }
        }

        @media (max-width: 440px) {
            width: calc(100% - 32px);
            height: 433px;
            padding: 64px 16px;

            .logo {
                width: 61px;
                height: 61px;
            }

            h1 {
                width: 100%;
                height: 96px;

                font-size: 32px;
                line-height: 120%;
                letter-spacing: -0.64px;
            }

            p {
                width: 329px;
                height: 104px;
                font-size: 16px;
                line-height: 150%;
            }

            &__button {
                width: 291px;
                height: 24.5px;
                padding: 18px 24px 18px 28px;

                span {
                    width: 62px;
                    height: 24px;
                    font-size: 16px;
                    line-height: 150%;
                    letter-spacing: 0.32px;
                }
            }
        }
    }
}