$mLeftMobile: calc((100% - 375px) / 2);

.cases {
    width: 1280px;
    margin: 0 auto;
    position: relative;

    .projects,
    .industrial-design {
        width: 100%;
        height: auto;

        .btn-all-cases {
            width: 198px;
            height: 24px;
            padding: 18px 28px 18px 24px;

            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 11px;
            align-items: center;

            margin-top: 64px;
            margin-left: calc(100% - 250px);

            text-align: right;
            border-radius: 48px;
            background: radial-gradient(70.71% 70.71% at 50% 50%, rgba(255, 255, 255, 0.12) 0%, rgba(0, 0, 0, 0.00) 100%), #000;

            cursor: pointer;

            span {
                color: #FFF;
                text-align: center;
                font-family: 'Satoshi', sans-serif;
                font-size: 17px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
            }

            .icon {
                width: 24px;
                height: 24px;

                background: url('../../assets/images/arr.svg') no-repeat;
            }
        }

        h1 {
            color: #262626;
            font-family: 'Satoshi', sans-serif;
            font-size: 64px;
            font-style: normal;
            font-weight: 700;
            line-height: 110%;
            letter-spacing: -1.28px;

            margin: 0 0 32px 0;
        }

        &__card-container {
            width: 100%;
            height: auto;

            display: flex;
            flex-wrap: wrap;
            gap: 16px;
        }

        @media (min-width: 768px) and (max-width: 1280px) {
            &__card-container {
                gap: 8px;
            }
        }

        @media (max-width: 440px) {
            width: calc(100% - 16px);

            h1 {
                font-size: 32px;              
                letter-spacing: -0.64px;
            }

            &__card-container {
                gap: 16px;
            }
        }
    }

    .discuss {
        height: 334px;
        padding: 83px 0;

        .logo {
            width: 61px;
            height: 61px;
            margin: 0 auto;
            background: url('../../assets/images/logo.svg') no-repeat;
            background-size: contain;
        }

        h1,
        p,
        span {
            position: relative;
            font-family: 'Satoshi', sans-serif;
            font-style: normal;
        }

        h1,
        p {
            text-align: center;
        }

        h1,
        span {
            font-weight: 700;
        }

        h1 {
            height: 48px;
            margin: 22px 0 0 0;

            color: #262626;
            font-size: 40px;
            line-height: 48px;
            letter-spacing: -0.8px;
        }

        p {
            width: 613px;
            height: 54px;
            margin: 24.5px auto 64.39px auto;

            color: #666;
            font-size: 18px;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: -0.36px;
        }

        &__button {
            width: 245.27px;
            height: 24px;
            padding: 18px 0;

            margin: 0 auto;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 11px;

            border-radius: 48px;
            background: radial-gradient(70.71% 70.71% at 50% 50%, rgba(255, 255, 255, 0.12) 0%, rgba(0, 0, 0, 0.00) 100%), #000;

            cursor: pointer;

            span {
                width: 64px;
                height: 20px;

                color: #FFF;
                text-align: center;
                font-size: 17px;
                font-weight: 700;
                line-height: 20px;
            }

            .icon {
                width: 24px;
                height: 24px;

                background: url('../../assets/images/enter_white.svg') no-repeat;
            }
        }

        @media (max-width: 440px) {
            width: calc(100% - 32px);
            height: 433px;
            padding: 64px 16px;

            .logo {
                width: 61px;
                height: 61px;
            }

            h1 {
                width: 100%;
                height: 96px;

                font-size: 32px;
                line-height: 120%;
                letter-spacing: -0.64px;
            }

            p {
                width: 329px;
                height: 104px;
                font-size: 16px;
                line-height: 150%;
            }

            &__button {
                width: 291px;
                height: 24.5px;
                padding: 18px 24px 18px 28px;

                span {
                    width: 62px;
                    height: 24px;
                    font-size: 16px;
                    line-height: 150%;
                    letter-spacing: 0.32px;
                }
            }
        }
    }

    .projects {
        padding: 64px 0;

        @media (max-width: 440px) {
            width: calc(100% - 32px);
            padding: 64px 16px;
        }
    }

    .industrial-design {
        padding: 64px 0 16px 0;

        @media (max-width: 440px) {
            width: calc(100% - 32px);
            padding: 64px 16px;
        }
    }

    .title {
        width: 100%;
        height: 669px;
        position: relative;

        &__header {
            width: 314px;
            height: 64px;
            position: absolute;
            left: 0;
            top: 302px;

            h1,
            span {
                font-family: 'Satoshi', sans-serif;
            }

            h1 {
                margin: 0;
                color: #262626;
                font-size: 64px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                letter-spacing: -2.56px;
            }

            span {
                position: absolute;
                right: 0;
                top: 5px;

                margin: 0;
                color: #262626;
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                letter-spacing: -0.6px;
            }
        }

        &__paragraph {
            width: 916px;
            height: 102px;

            position: absolute;
            left: 0;
            top: 430px;

            color: #262626;
            font-family: 'Satoshi', sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
        }

        @media (max-width: 440px) {
            height: 398px;
            width: 343px;
            padding: 0 16px;

            &__header {
                width: 155px;
                height: 32px;

                top: 128px;
                left: calc($mLeftMobile + 16px);

                h1 {
                    font-size: 32px;
                    line-height: 100%;
                    letter-spacing: -1.28px;
                }

                span {
                    right: 0;
                    top: 0;
                    font-size: 6.64px;
                    line-height: 100%;
                    letter-spacing: -0.266px;
                }
            }

            &__paragraph {
                width: 343px;
                height: 110px;

                top: 224px;
                left: calc($mLeftMobile + 16px);

                font-size: 16px;
                line-height: 140%;
            }
        }
    }

    @media (min-width: 768px) and (max-width: 1280px) {
        width: 960px;
    }

    @media (max-width: 440px) {
        width: 375px;
    }
}