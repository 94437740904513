// @font-face {
//     font-family: 'Satoshi';
//     src: url('./assets/fonts/Satoshi-Variable.woff2') format('woff2'),
//         url('./assets/fonts/Satoshi-Variable.woff') format('woff'),
//         url('./assets/fonts/Satoshi-Variable.ttf') format('truetype');
//     font-weight: 300 900;
//     font-display: swap;
//     font-style: normal;
// }

a {
    text-decoration: none;
}

html {
    // scroll-behavior: smooth;

    // ::-webkit-scrollbar {
    //     display: none;
    // }

    overflow-x: hidden;
}

body {
    margin: 0;
}