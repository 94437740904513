.cookie-consent {
    width: 1216px;
    height: 48px;
    padding: 32px;

    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 30px;

    z-index: 100;

    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 8px 28px 0px rgba(0, 0, 0, 0.28);

    &__header {
        width: 119px;
        height: 20px;

        position: absolute;
        left: 32px;
        top: 32px;

        display: flex;
        flex-direction: row;
        justify-content: space-between;

        h2 {
            margin: 0;
            color: #484848;
            font-family: 'Satoshi', sans-serif;
            font-style: normal;
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
        }

        &__icon {
            width: 16px;
            height: 16px;

            background: url('../../../assets/images/cookie/lock.svg') no-repeat;
            background-size: contain;
        }
    }

    p {
        height: 20px;
        position: absolute;
        left: 32px;
        bottom: 32px;

        margin: 0;

        color: #0D0C22;
        font-family: 'Satoshi', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;

        a {
            color: #428BFF;
        }
    }

    &__buttons {
        width: 246px;
        height: 46px;

        position: absolute;
        right: 32px;
        top: 33px;

        display: flex;
        flex-direction: row;
        justify-content: space-between;

        button {
            width: 120px;
            height: 46px;
            position: relative;
            // padding: 13px 23px 13px 23px;

            font-family: 'Satoshi', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;

            border-radius: 8px;
            border: 1px solid #222;

            cursor: pointer;

            &:nth-child(1) {
                color: #222;
            }

            &:nth-child(2) {
                color: #FFF;
                background: #222;
            }
        }
    }

    @media (max-width: 450px) {
        width: 308px;
        height: 104px;
        bottom: 43px;
        padding: 16px;

        &__header {
            display: none;
        }

        p {
            width: 309px;
            height: 60px;
            top: 16px;
            left: 16px;
        }

        &__buttons {
            left: 16px;
            top: 88px;
            height: 32px;

            justify-content: flex-start;
            gap: 8px;

            button {
                width: 81px;
                height: 32px;

                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
            }
        }
    }
}

.hidden {
    display: none;
}