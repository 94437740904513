.project-card {
    height: 552px;
    padding: 8px;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    border-radius: 16px;
    background: #F2F2F2;

    &__category {
        height: 24px;
        width: auto;
        padding: 8px;

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;

        &__icon {
            width: 24px;
            height: 24px;
            position: relative;
            background: url('../../../assets/images/layers.svg') no-repeat;
        }

        &__separator {
            width: 4.5px;
            height: 4.5px;
            background: #AAA;
            opacity: 0.5;
            border-radius: 50%;
        }

        span {
            margin: 0;
            color: #AAA;
            font-family: 'Satoshi', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    img {
        position: relative;
        border-radius: 8px;
    }


    &__text {
        height: 43px;
        width: auto;
        position: absolute;
        left: 32px;
        bottom: 32px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h3,
        p {
            margin: 0;
            text-shadow: 0px 1px 32px rgba(8, 24, 93, 0.25);
            font-family: 'Satoshi', sans-serif;
            font-style: normal;
            line-height: normal;
        }

        h3 {
            font-size: 32px;
            font-weight: 700;
        }

        p {
            font-size: 16px;
            font-weight: 400;
        }
    }

    @media (min-width: 768px) and (max-width: 1280px) {
        height: 400px;
    }

    @media (max-width: 440px) {
        padding: 4px;

        &__category {
            height: 16px;
            padding: 2.25px;
            gap: 2.25px;

            &__icon {
                width: 16px;
                height: 16px;
                background-size: contain;
            }

            &__separator {
                width: 1.125px;
                height: 1.125px;
            }

            span {
                font-size: 8px;
            }
        }

        &__text {
            height: 25px;
            left: 19px;

            bottom: 21.44px;

            h3,
            p {
                text-shadow: 0px 0.281px 9px rgba(8, 24, 93, 0.25);
            }

            h3 {
                font-size: 18.235px;
            }

            p {
                font-size: 10px;
            }
        }
    }
}